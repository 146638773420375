import compile from "~/utils/compile";
import template from "~/utils/template";

export const ticketClaimUrl = compile(template("ticket_transfer_claims/:reference"));
export const requestPasswordUrl = compile(template("users/password/new"));
export const editPasswordUrl = compile(template("users/password/edit"));
export const signInUrl = compile(template("users/sign_in"));
export const signUpUrl = compile(template("users/sign_up"));

export const rootUrl = compile(template(""));
export const categoryUrl = compile(template("c/:category"));
export const newEventUrl = compile(template("organiser/manage/events/new"));
export const newLegacySubscriptionUrl = compile(template("community/subscriptions/new"));
export const orderUrl = compile(template("orders/:orderId"));
export const editOrderUrl = compile(template("orders/:orderId/edit"));
export const paymentOrderUrl = compile(template("orders/:orderId/payment"));
export const editOrderEinvoiceUrl = compile(template("orders/:orderId/einvoices/:transactionId/edit"));
export const orderEinvoiceUrl = compile(template("orders/:orderId/einvoices/:transactionId"));
export const orderAddonsTemplate = template("orders/:orderId/addons");
export const orderAddonsUrl = compile(orderAddonsTemplate);
export const eventSocialTemplate = template("e/:slug-:id/social");
export const eventSocialUrl = compile(eventSocialTemplate);
export const embeddedEventUrl = compile(template("embed/:slug([^/]+)-:id"));
export const embeddedTicketsUrl = compile(template("embed/:slug([^/]+)-:id/tickets/:code?"));
export const embeddedOrderUrl = compile(template("embed/:slug([^/]+)-:id/orders/:orderId"));
export const embeddedOrderPaymentUrl = compile(template("embed/:slug([^/]+)-:id/orders/:orderId/payment"));
export const embeddedEditOrderUrl = compile(template("embed/:slug([^/]+)-:id/orders/:orderId/edit"));
export const eventTicketsUrl = compile(template("e/:slug([^/]+)-:id/select/:code?"));

export const pageUrl = compile(template("pages/:page"));
export const landingUrl = compile(template("l/:page"));
export const blogUrl = compile(template("blog"));
export const logOutUrl = compile(template("users/sign_out"));
export const userProfileUrl = compile(template("users/:slug"));
export const eventTemplate = template("e/:slug([a-zA-Z0-9_-]+)-:id");
export const eventUrl = compile(eventTemplate);
export const eventSlugUrl = compile(template("e/:slug-:id"));
export const searchUrl = compile(template("search"));
export const searchPageTemplate = template("p/:slug");
export const categoryPageTemplate = template("c/:category-c");
export const categoryPageUrl = compile(categoryPageTemplate);
export const subcategoryPageTemplate = template("c/:subcategory-sc");
export const subcategoryPageUrl = compile(subcategoryPageTemplate);
export const subcategoryWithCategoryPageTemplate = template("c/:category-c/:subcategory-sc");
export const subcategoryWithCategoryPageUrl = compile(subcategoryWithCategoryPageTemplate);
export const locationPageTemplate = template("c/:slug-l");
export const locationPageUrl = compile(locationPageTemplate);
export const searchPageUrl = compile(searchPageTemplate);
export const orderTicketsTemplate = template("orders/tickets");
export const orderTicketsUrl = compile(orderTicketsTemplate);
export const ticketPdfUrl = compile(template("e/:eventId/tickets/:id.pdf"));
export const eventVenueUrl = compile(template("organiser/manage/events/:eventId/venue"));
export const eventVenueSettingsUrl = compile(template("organiser/manage/events/:eventId/venue/settings"));
export const eventPartnersUrl = compile(template("organiser/manage/events/:eventId/partners"));
export const newEventPartnerUrl = compile(template("organiser/manage/events/:eventId/partners/new"));
export const eventContactUrl = compile(template("e/:eventId/contact/new"));
export const eventContactActionUrl = compile(template("e/:eventId/contact"));
export const eventEditorUrl = compile(template("organiser/manage/events/:eventId/editor/:page"));
export const editEventUrl = compile(template("organiser/manage/events/:eventId/edit/:page?"));
export const newSubEventUrl = compile(template("organiser/manage/events/:eventId/sub_events/new"));
export const eventSetupUrl = compile(template("organiser/manage/events/:eventId/setup"));
export const eventSubEventsUrl = compile(template("organiser/manage/events/:eventId/sub_events"));
export const eventWeekSubEventsUrl = compile(template("organiser/manage/events/:eventId/sub_events/week"));
export const eventMonthSubEventsUrl = compile(template("organiser/manage/events/:eventId/sub_events/month"));
export const joinEventWaitlistUrl = compile(template("ticket_buyer/events/:eventId/waiting_list"));
export const eventEventPageUrl = compile(template("organiser/manage/events/:eventId/event_page"));
export const eventWaitlistUrl = compile(template("organiser/manage/events/:eventId/waitlist"));
export const cancelEventTemplate = template("organiser/manage/events/:eventId/cancel");
export const cancelEventUrl = compile(cancelEventTemplate);
export const postponeEventTemplate = template("organiser/manage/events/:eventId/postpone");
export const postponeEventUrl = compile(postponeEventTemplate);
export const eventReportTemplate = template("organiser/manage/events/:eventId/reports");
export const eventReportUrl = compile(eventReportTemplate);
export const eventBookingQuestionsTemplate = template("organiser/manage/events/:eventId/booking_questions");
export const newBookingQuestionTemplate = template("organiser/manage/events/:eventId/booking_questions/new");
export const newBookingQuestionUrl = compile(newBookingQuestionTemplate);
export const editBookingQuestionTemplate = template("organiser/manage/events/:eventId/booking_questions/:id/edit");
export const editBookingQuestionUrl = compile(editBookingQuestionTemplate);
export const eventBookingQuestionsUrl = compile(eventBookingQuestionsTemplate);
export const eventAttendeesUrl = compile(template("e/:eventId/attendees"));
export const eventAccessCodesTemplate = template("organiser/manage/events/:eventId/access_codes/:id?");
export const eventDesignUrl = compile(template("organiser/manage/events/:eventId/design"));
export const eventAccessCodesUrl = compile(eventAccessCodesTemplate);
export const eventPromotionTemplate = template("organiser/manage/events/:eventId/promotion");
export const eventZapierTemplate = template("organiser/manage/events/:eventId/zapier");
export const eventZapierUrl = compile(eventZapierTemplate);
export const eventIcalTemplate = template("e/:eventId/ical");
export const eventIcalUrl = compile(eventIcalTemplate);
export const eventBlacklistTemplate = template("organiser/manage/events/:eventId/blacklist");
export const eventBlacklistUrl = compile(eventBlacklistTemplate);
export const eventOverviewTemplate = template("organiser/manage/events/:eventId/overview");
export const eventWidgetTemplate = template("organiser/manage/events/:eventId/ticket_widget");
export const eventWidgetUrl = compile(eventWidgetTemplate);
export const eventOverviewUrl = compile(eventOverviewTemplate);
export const onlineEventTemplate = template("organiser/manage/events/:eventId/online_event");
export const onlineEventUrl = compile(onlineEventTemplate);
export const onlineEventPreviewUrl = compile(template("e/:eventId/online_event_preview"));
export const onlineEventPageTemplate = template("e/:eventId/online");
export const onlineEventPageUrl = compile(onlineEventPageTemplate);
export const newEventCampaignTemplate = template("organiser/manage/events/:eventId/campaigns/new");
export const newEventCampaignUrl = compile(newEventCampaignTemplate);
export const eventCampaignsTemplate = template("organiser/manage/events/:eventId/campaigns");
export const eventCampaignsUrl = compile(eventCampaignsTemplate);
export const eventCampaignTemplate = template("organiser/manage/events/:eventId/campaigns/:campaignId");
export const eventPromotersUrl = compile(template("organiser/manage/events/:eventId/promoters"));
export const eventSmartLinksUrl = compile(template("organiser/manage/events/:eventId/smart_links"));
export const smartLinksUrl = compile(template("organiser/promote/smart_links"));
export const eventCampaignUrl = compile(eventCampaignTemplate);
export const eventCampaignConditionsTemplate = template(
  "organiser/manage/events/:eventId/campaigns/:campaignId/conditions",
);
export const eventCampaignConditionsUrl = compile(eventCampaignConditionsTemplate);
export const eventOverviewDataUrl = compile(template("e/:eventId/overview_data"));
export const eventCampaignEffectsTemplate = template("organiser/manage/events/:eventId/campaigns/:campaignId/effects");
export const eventCampaignEffectsUrl = compile(eventCampaignEffectsTemplate);
export const eventCampaignTemplateTemplate = template(
  "organiser/manage/events/:eventId/campaigns/:template(early_bird|group_discount|promotional_code|family_discount|unlock|2in1)/:campaignId",
);
export const eventCampaignTemplateUrl = compile(eventCampaignTemplateTemplate);
export const eventCampaignTemplateConditionsTemplate = `${eventCampaignTemplateTemplate}/conditions`;
export const eventCampaignTemplateConditionsUrl = compile(eventCampaignTemplateConditionsTemplate);
export const eventCampaignTemplateEffectsTemplate = `${eventCampaignTemplateTemplate}/effects`;
export const eventCampaignTemplateEffectsUrl = compile(eventCampaignTemplateEffectsTemplate);
export const eventExperienceMoreCampaignTemplateTemplate = template(
  "organiser/manage/events/:eventId/campaigns/experience_more/:campaignId",
);
export const eventExperienceMoreCampaignTemplateUrl = compile(eventExperienceMoreCampaignTemplateTemplate);
export const eventOrganiserTicketsTemplate = template("organiser/manage/events/:eventId/organizer/tickets/:id?");
export const eventOrganiserTicketsUrl = compile(eventOrganiserTicketsTemplate, {});
export const eventFacebookTemplate = template("organiser/manage/events/:eventId/facebook");
export const eventFacebookUrl = compile(eventFacebookTemplate);
export const cloneEventUrl = compile(template("organiser/manage/events/:eventId/clone"));
export const eventOrderDetailsTemplate = template("organiser/manage/events/:eventId/orders/:orderId");
export const eventOrderDetailsUrl = compile(eventOrderDetailsTemplate);
export const eventTicketDetailsUrl = compile(template("organiser/manage/events/:eventId/tickets/:id"));
export const eventOrdersTemplate = template("organiser/manage/events/:eventId/orders");
export const eventOrdersUrl = compile(eventOrdersTemplate);

export const eventPlansTemplate = template("organiser/manage/events/:eventId/plans");
export const eventPlansUrl = compile(eventPlansTemplate);
export const eventInsightsTemplate = template("organiser/manage/events/:eventId/insights");
export const eventTicketsExportUrl = compile(template("organiser/manage/events/:eventId/organizer/tickets/export"));
export const eventTicketsImportUrl = compile(template("organiser/manage/events/:eventId/organizer/tickets/import"));
export const eventTicketsGeneratorUrl = compile(
  template("organiser/manage/events/:eventId/organizer/tickets/generator"),
);
export const eventInsightsUrl = compile(eventInsightsTemplate);
export const eventAuditLogTemplate = template("organiser/manage/events/:eventId/log");
export const eventPaymentMethodsTemplate = template("organiser/manage/events/:eventId/payments");
export const eventPaymentMethodsUrl = compile(eventPaymentMethodsTemplate);
export const eventScannerSettingsUrl = compile(template("organiser/manage/events/:eventId/scanner_settings"));
export const eventScanningOverviewUrl = compile(template("organiser/manage/events/:eventId/scanning_overview"));
export const eventTillSettingsUrl = compile(template("organiser/manage/events/:eventId/till_settings"));
export const eventTransferUrl = compile(template("organiser/manage/events/:eventId/transfer"));
export const eventVenueCategoriesTemplate = template("organiser/manage/events/:eventId/venue/categories");
export const eventVenueCategoriesUrl = compile(eventVenueCategoriesTemplate);
export const editServiceOrderTemplate = template("organiser/manage/events/:eventId/service_orders/:orderId/edit");
export const editServiceOrderUrl = compile(editServiceOrderTemplate);
export const eventAnalyticsTemplate = template("organiser/manage/events/:eventId/analytics");
export const eventAnalyticsUrl = compile(eventAnalyticsTemplate);
export const editEventServiceOrderTemplate = template("organiser/manage/events/:eventId/service_orders/:id/edit");
export const editEventServiceOrderUrl = compile(editEventServiceOrderTemplate);
export const eventTicketsPrintUrl = compile(template("e/:eventId/tickets/print.pdf"));
export const eventTicketUrl = compile(template("e/:eventId/tickets/:id.pdf"));
export const eventPassUrl = compile(template("e/:eventId/tickets/:id.pkpass"));
export const eventAppTemplate = template("organiser/manage/events/:eventId/app");
export const eventAppUrl = compile(eventAppTemplate);
export const completeEventTemplate = template("organiser/manage/events/:eventId/complete");
export const completeEventUrl = compile(completeEventTemplate);
export const eventDiscoverTemplate = template("organiser/manage/events/:eventId/discover");
export const eventDiscoverUrl = compile(eventDiscoverTemplate);
export const eventConnectVenueTemplate = template("organiser/manage/events/:eventId/connect_venue");
export const eventConnectVenueUrl = compile(eventConnectVenueTemplate);
export const eventAdvertisingTemplate = template("organiser/manage/events/:eventId/advertising");
export const eventAdvertisingUrl = compile(eventAdvertisingTemplate);

export const addressesTemplate = template("profile/addresses");
export const addressesUrl = compile(addressesTemplate);
export const myMembershipsTemplate = template("profile/memberships");
export const myMembershipsUrl = compile(myMembershipsTemplate);
export const myTicketsUrl = compile(template("profile/my_tickets/:filter(past|liked)?"));
export const editSubscriptionUrl = compile(template("organiser/subscriptions/:subscriptionId/edit"));
export const organiserDashboardUrl = compile(template("organiser/dashboard"));
export const organiserCampaignsTemplate = template("organiser/promote/campaigns");
export const organiserCampaignsUrl = compile(organiserCampaignsTemplate);
export const organiserCampaignTemplate = template("organiser/promote/campaigns/:campaignId");
export const organiserCampaignUrl = compile(organiserCampaignTemplate);
export const organiserCampaignConditionsTemplate = template("organiser/promote/campaigns/:campaignId/conditions");
export const organiserCampaignConditionsUrl = compile(organiserCampaignConditionsTemplate);
export const organiserCampaignEffectsTemplate = template("organiser/promote/campaigns/:campaignId/effects");
export const organiserCampaignEffectsUrl = compile(organiserCampaignEffectsTemplate);
export const webhooksUrl = compile(template("organiser/webhooks/:id?"));
export const billettoAdvertisingTemplate = template("organiser/billetto_advertising");
export const billettoAdvertisingUrl = compile(billettoAdvertisingTemplate);
export const campaignsTemplate = template("organiser/promote/campaigns");
export const campaignsUrl = compile(campaignsTemplate);
export const campaignTemplate = template("organiser/promote/campaigns/:campaignId");
export const campaignUrl = compile(campaignTemplate);
export const venuesUrl = compile(template("organiser/venues/:venueId?"));
export const eventManagerUrl = compile(template("event_manager"));
export const eventManagerEventOrderPdfUrl = compile(template("event_manager/events/:eventId/orders/:id.pdf"));
export const tillEventTicketsTemplate = template("event_manager/events/:eventId/till");
export const tillEventTicketsUrl = compile(tillEventTicketsTemplate);

export const ticketBuyerEventPdfUrl = compile(template("ticket_buyers/:ticketBuyerId/attendances/events/:eventId/pdf"));
export const locationCategoryPageUrl = compile(template("c/:location-l/:category-c"));
export const locationSubcategoryPageUrl = compile(template("c/:location-l/:category-c/:subcategory-sc"));
export const locationTypePageUrl = compile(template("c/:location-l/:type-t"));
export const eventRatingsUrl = compile(template("event_ratings"));
