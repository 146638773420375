import {Listbox, Transition} from "@headlessui/react";
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/20/solid";
import {createElement, forwardRef, Fragment, useCallback, useMemo} from "react";

import ErrorBoundary from "~/components/ErrorBoundary";
import Loading from "~/icons/Loading";
import classNames from "~/utils/classNames";

export default forwardRef(function Select(
  {
    native = false,
    multiple = false,
    options,
    value,
    valueMapper,
    onChange,
    placeholder,
    icon,
    size,
    className,
    disabled,
    loading,
    allowBlank = false,
    ...props
  },
  ref,
) {
  const normalizedValue = useMemo(() => (multiple ? [].concat(value) : value), [multiple, value]);
  const handleChange = useCallback(
    (event) => {
      const {value} = event.target;
      if (typeof valueMapper === "function") return onChange?.(valueMapper(value));
      return onChange?.(event);
    },
    [onChange, valueMapper],
  );

  const selectedLabel = useMemo(
    () =>
      multiple
        ? options
            ?.filter((option) => normalizedValue?.includes(option.value))
            ?.map((option) => option.label)
            ?.join(", ")
        : options?.find((option) => option.value === normalizedValue)?.label,
    [multiple, options, normalizedValue],
  );

  const sizeStyles =
    {
      xs: "pl-2 pr-6 py-1.5 text-xs rounded",
      sm: "pl-2 pr-8 py-2 leading-4 text-sm rounded-md",
      lg: "pl-3 pr-10 py-2 text-base rounded-md",
      xl: "pl-3 pr-10 py-3 text-base rounded-md",
    }[size] || "pl-3 pr-10 py-2 text-sm rounded-md";

  if (native)
    return (
      <select
        {...props}
        onChange={handleChange}
        value={normalizedValue}
        disabled={disabled}
        name={props.name || props.id}
        ref={ref}
        className={classNames(
          className,
          sizeStyles,
          "bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 billetto-input relative w-full shadow-sm pl-3",
          "border-solid border border-gray-200 dark:border-gray-500",
          "pr-10 text-left cursor-default focus:outline-none sm:text-sm disabled:bg-gray-100 disabled:text-gray-400 disabled:dark:text-gray-300",
        )}
      >
        {allowBlank ? <option value="">{placeholder}</option> : null}
        {options?.map(({label, value, disabled}, idx) => (
          <option key={value ?? `option-${idx}`} value={value} disabled={disabled}>
            {label}
          </option>
        ))}
      </select>
    );

  return (
    <ErrorBoundary>
      <Listbox
        multiple={multiple}
        as="div"
        disabled={disabled}
        className="relative flex items-center"
        value={normalizedValue}
        onChange={onChange}
        ref={ref}
      >
        {({open}) => (
          <>
            <Listbox.Button
              {...props}
              name={props.name || props.id}
              className={classNames(
                className,
                sizeStyles,
                disabled ? "bg-gray-200 dark:bg-gray-600 opacity-70" : "bg-white dark:bg-gray-700",
                "billetto-input relative w-full shadow-sm pl-3 pr-10 text-left cursor-default focus:outline-none sm:text-sm",
              )}
            >
              <span className={classNames("block text-gray-900 dark:text-gray-100", multiple ? null : "truncate")}>
                {icon ? createElement(icon, {className: "h-4 w-4 inline mr-2"}) : null}
                {selectedLabel ? (
                  <span>{selectedLabel}</span>
                ) : placeholder ? (
                  <span className="text-gray-400">{placeholder}</span>
                ) : (
                  <span className="text-gray-400" dangerouslySetInnerHTML={{__html: "&nbsp;"}} />
                )}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                {loading ? (
                  <Loading className="h-4 w-4" />
                ) : (
                  <ChevronUpDownIcon className="h-3 w-3 text-gray-400" aria-hidden="true" />
                )}
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="top-0 absolute z-[300] mt-1 w-full bg-white dark:bg-gray-700 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {allowBlank ? <Listbox.Option value="">{placeholder}</Listbox.Option> : null}
                {options?.filter(Boolean)?.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    className={({active}) =>
                      classNames(
                        active ? "text-white bg-brand-500 " : "text-gray-900 dark:text-gray-200",
                        "cursor-default select-none relative py-2 pl-3 pr-9",
                      )
                    }
                    disabled={option.disabled}
                    value={option.value}
                  >
                    {({selected, active}) => (
                      <>
                        <span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate")}>
                          {option.label}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white dark:text-gray-200" : "text-brand-500",
                              "absolute inset-y-0 right-0 flex items-center pr-4",
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>
    </ErrorBoundary>
  );
});
